<script>
	import { Bar, mixins } from 'vue-chartjs'
	import {HTTPJSON} from '@/api/http-common'
	export default {
		props: {
			camid: Number
		},
		mixins: [mixins.reactiveData],
	//props: ['camid'],
	extends: Bar,
	data() { 
		return {
			props: {
				camid: Number
			},
			chartData: '',
			options: {
				title: {
					display: true,
					fontSize: 24,
					text: 'AI Fire Certainty'
				},
				scales: {
					yAxes: [{
							ticks: {
								beginAtZero: true,
								min: 0,
								max: 80
							}
					}],
					xAxes: [{
							ticks: {
								beginAtZero: true
							}
					}]
				},
				maintianAspectRatio: false
			}
		}
	},
	mounted() {
		//this.fetchData()
		const url = String(process.env.VUE_APP_CHART_BASE_URL)  + "/getai/" + String(this.camid)	
		
		setInterval(() => {
			HTTPJSON.get(url)
			.then(response => {
				const responseData = response.data
				this.chartData = {
					labels: ["EDWIN-ICE " + (responseData.edwinai*100).toFixed(3) + "%", "Hist-AI " + (responseData.histai*100).toFixed(3) + "%"],
					datasets: [{
					label: 'Fire Certainty (percentage)',
					backgroundColor: ['#FE6C29', '#33537A'],
					data: [(responseData.edwinai*100),(responseData.histai*100)],
					//scale: 2
					}]
				}
			})
			.catch(e => {
				console.log(e)
			})
			}, 1500)
			
		this.renderChart(this.chartData, this.options)
		//console.log("chartData1: " + this.chartData, this.options)
	},
	created() {

	},
		components: {
			'edwinAIbarchart': this
		}
	}



</script>