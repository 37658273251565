

<script>
	import { mixins, Doughnut } from 'vue-chartjs'
	import {HTTPJSON} from '@/api/http-common'
	export default {
		props: {
			camid: Number
		},
		mixins: [mixins.reactiveData],
	//props: ['camid'],
	extends: Doughnut,
	data() { 
		return {
			props: {
				camid: Number
			},
			chartData: '',
			labels: [],
			options: {
				title: {
					display: true,
					fontSize: 24,
					text: 'Infrared Intensity Levels'
				}
				
			}
		}
	},
	mounted() {
		//this.fetchData()
		const url = String(process.env.VUE_APP_CHART_BASE_URL) + "/summary/" + String(this.camid)
		//console.log(url)
		
		setInterval(() => {
			HTTPJSON.get(url)
			.then(response => {
				const responseData = response.data.summary
				this.chartData = {
					labels: ["Cold " + (responseData["cold"]*100).toFixed(3) + "%", "Warm " + (responseData["warm"]*100).toFixed(3) + "%", "Hot " + (responseData["hot"]*100).toFixed(3) + "%", "Danger " + (responseData["danger"]*100).toFixed(3) + "%", "Critical " + (responseData["critical"]*100).toFixed(3) + "%"],
					datasets: [{
					label: '',
					backgroundColor: ['#33537A', '#fcd703', '#FE6C29', '#db4002', '#590000' ],
					data: [responseData["cold"], responseData["warm"], responseData["hot"], responseData["danger"], responseData["critical"] ],
					}]
				}
				//console.log("cold:" + responseData["cold"])
			})
			.catch(e => {
				console.log(e)
			})
			}, 1500)
			
		this.renderChart(this.chartData, this.options)
		//console.log("chartData1: " + this.chartData, this.options)
	},
	created() {
			//console.log("prop2 is:" + this.camid)
	},
		components: {
			'summary-chart': this
		}
	}



</script>