<script>
	import { mixins, Doughnut } from 'vue-chartjs'
	import {HTTPJSON} from '@/api/http-common'
	export default {
		props: {
			camid: Number
		},
		mixins: [mixins.reactiveData],
	//props: ['camid'],
	extends: Doughnut,
	data() { 
		return {
			//props: {
			//	camid: Number
			//},
			chartData: '',
			labels: [],
			options: {
				title: {
					display: true,
					fontSize: 24,
					text: 'EDWIN-ICE AI Fire Certainty'
				}
				
			}
		}
	},
	mounted() {
		//this.fetchData()
		console.log(this.camid)
		const url = String(process.env.VUE_APP_CHART_BASE_URL) + "/getai/" + String(this.camid)
		//const url = String("http://localhost:7001") + "/edwinai"
		//console.log(url)
		
		setInterval(() => {
			HTTPJSON.get(url)
			.then(response => {
				const responseData = response.data
				this.chartData = {
					labels: ["EDWIN-ICE Fire Certainty " + (responseData.edwinai*100).toFixed(3) + "%", "Hist-AI Fire Certainty " + (responseData.histai*100).toFixed(3) + "%"],
					datasets: [{
					label: '',
					backgroundColor: ['#FE6C29', '#33537A' ],
					data: [responseData.edwinai, (1 - responseData.edwinai)],
					}]
				}
				//console.log("cold:" + responseData["cold"])
			})
			.catch(e => {
				console.log(e)
			})
			}, 1500)
			
		this.renderChart(this.chartData, this.options)
		//console.log("chartData1: " + this.chartData, this.options)
	},
	created() {
			//console.log("prop2 is:" + this.camid)
	},
		components: {
			'edwinAIchart': this
		}
	}



</script>