<template>
  <div class="dashboard">
    <h1 class="display-3 text-center pa-8">Available Live Streams</h1>
    <img src="@/assets/EDWIN_logo_2.png" style="maxWidth: 300px" class="center">
    <div v-if="!loggedInUser.active" >
      <v-layout align-center justify-center row fill-height>
      Your account has not yet been activated. Please finish account setup on the My Profile page and contact your group admin to accept your request for full access to live feeds and alerts.
      </v-layout>
    </div>
    <div v-if="loggedInUser.active">
    <v-layout align-center justify-center row fill-height>
      <v-expansion-panels accordion hover style="maxWidth: 1500px;">
        <v-expansion-panel v-for="camera in finalCameras" :key="camera.id">
          <v-expansion-panel-header disable-icon-rotate>
            <template v-slot:actions>
              <v-icon v-if="camera.status === 'good'" color="teal">check_circle</v-icon>
              <v-icon v-else-if="camera.status === 'offline'" color="warning">warning</v-icon>
              <v-icon v-else-if="camera.status === 'alert'" color="error">error</v-icon>
              <v-icon v-else color="#2196F3">info</v-icon>
            </template>
            {{camera.name}}
            <v-col cols="6"><em>{{camera.groupName}}</em></v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="grey--text">
            <v-card
              class="mx-auto"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">{{ camera.name }}, {{camera.coordinates}}<span class="pl-4 pa-1 pb-2">View on <a :href="`https://www.google.com/maps/search/?api=1&query=${camera.coordinates}`" target="_blank">Google Maps</a></span></div>
                  
                  <v-list-item-title class="headline mb-1">{{ camera.name }}</v-list-item-title>
                  <!--<v-list-item-subtitle>
                    <v-alert v-if="camera.status === 'good'" text color="teal">The camera status is {{camera.status}}</v-alert>
                    <v-alert v-else-if="camera.status === 'offline'" text color="warning">The camera status is {{camera.status}}</v-alert>
                    <v-alert v-else-if="camera.status === 'alert'" text color="error">The camera status is {{camera.status}}</v-alert>
                    <v-alert v-else text color="#2196F3">The camera status is unknown ({{camera.status}})</v-alert>
                  </v-list-item-subtitle>-->
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line v-if="camera.streams.length > 0" >
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Streams</v-list-item-title>
                    <v-container>
                    <v-row>
                      <v-col class = "player" v-for="stream in camera.streams" :key="stream.id">
                        <videoPlayer
                        :options="stream.fullStream">
                        </videoPlayer>
                       </v-col>
                  </v-row>
                  <v-row>
                    
                  </v-row>
                  <v-row>
                    <v-col style="maxWidth: 300px;"> 
                        <edwinAIbarchart v-bind:camid=camera.id></edwinAIbarchart>
                    </v-col>
                    <v-col style="maxWidth: 400px;"> 
                        <histogram-chart v-bind:camid=camera.id></histogram-chart>
                      </v-col>
                    <v-col style="maxWidth: 400px;"> 
                        <histogram-summary v-bind:camid=camera.id></histogram-summary>
                    </v-col>
                  </v-row>
                </v-container>
                </v-list-item-content> 
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-content v-if="camera.alerts">
                  <v-list-item-title class="headline mb-1" v-if="getActiveAlerts(camera.alerts, camera)">Potential Alerts:</v-list-item-title>
                  <v-list-item-title class="headline mb-1" v-else>No Active Alerts</v-list-item-title>



                  <v-list-item-subtitle v-for="alerts in camera.alerts" :key="alerts.id">
                    <v-alert v-if="alerts.alert_status !== 'dismissed'" 
                      text 
                      v-bind:color="alerts.color" 
                      dismissible 
                      @input="dismissAlert(alerts.id, alerts.camera_id, alerts.alert_type), getAlertCount(alerts.id), dismissedAlert = true, dismissedAlertId = alerts.id"
                    >
                      {{formatDate(alerts.createdAt)}}<br>
                      {{alerts.alert_type}}, {{alerts.message}}
                      <br><a :href="'/alert/?id='+ alerts.id" target=blank>See alert</a>
                      <!--<ul>
                        <li v-for="filepath in alerts.filepath" :key=filepath.filename><a :href=filepath.path target=blank>{{filepath.filename}}</a></li>
                        <li v-if="alerts.videoClip"><a :href=alerts.videoClip target=blank>video clip</a></li>
                      </ul>-->







                    </v-alert>
                  </v-list-item-subtitle>



                </v-list-item-content> 
              </v-list-item>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-layout>
    <v-snackbar
      v-model="dismissedAlert"
      top
      :timeout="3000"
    >
      The alert has been dismissed
      <v-btn
        color="red"
        text
        @click="dismissedAlert = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</div>  
</template>

<script>
// @ is an alias to /src
import {HTTP} from '@/api/http-common';
import {videoPlayer} from 'vue-videojs7'
//import moment from 'vue-moment'

export default {
  data() {
    return{
      finalCameras: [],
      playerOptions: {
        // videojs and plugin options
        height: '360',
        autoplay: false,
        sources: {
          type: "application/x-mpegURL",
          src: "http://69.162.231.249:4040/stream/d471ec7b-771e-418c-87ab-cb1c003f5ad0/index.m3u8"
        }
      },
      generalURL: process.env.VUE_APP_PROXY_BASE_URL,
      streamURLdone: '',
      loggedInUserCameras: [],
      dismissedAlert: false,
      dismissedAlertId: '',
      selected: [],
      loggedInUser: []
    }
  },
  created() {
    this.getLoggedInUser()
    this.getLoggedInUserCameras().then(() =>{
      let generalURL = this.generalURL
      let finalCameraStreams = this.loggedInUserCameras
      finalCameraStreams.forEach(function(camera){
        if(camera.streams.length){
          camera.streams.forEach(function(stream){
            let url = generalURL + stream.url
            stream.fullStream = {
              height: '360',
              autoplay: false,
              sources: {
                type: "application/x-mpegURL",
                src: url
              }
            }
            //console.log('NEW STREAM:',JSON.stringify(stream))
          })
        }

      })
      this.finalCameras = finalCameraStreams
    }).catch(e => {
      console.log(e)
    });
  },
  mounted() {

  },
  methods: {
    getLoggedInUser: function(){
      if (this.$auth.user.email) {
        return HTTP.get('user/' + this.$auth.user.email).then(response => {
        if (response.data.active === true){
          this.loggedInUser = response.data
          }
        }) 
      }else return
    },
    getLoggedInUserCameras: function(){
      return HTTP.get('/camera/list/' + this.$auth.user.email).then(response => {
        this.loggedInUserCameras = response.data
        this.loggedInUserCameras.forEach(function(camera){
            
            //console.log("Image filepath: ", camera.alerts.length)
            var alertindex = 0
            while (alertindex < camera.alerts.length){
              var images = camera.alerts[alertindex].filepath.split(",")
              var pathdict = []
              var pathindex = 0;
              while (pathindex < images.length) {
                var pathstring = process.env.VUE_APP_CHART_BASE_URL + "/" + images[pathindex].replace('[','').replace(']','' ).replace(' ','' ).replace("'","" ).slice(0,-1)
                  var fname = pathstring.split("/")
                  fname = fname[fname.length -1]
                pathdict.push({"filename": fname, "path": pathstring}) 
                pathindex++;
              }
              camera.alerts[alertindex].filepath = pathdict
              //console.log("Filepaths: ", camera.alerts[alertindex].filepath)
              alertindex++;
            }
                      
        })
        //console.log('Get cameras for logged in user: ', JSON.stringify(this.loggedInUserCameras))
      })
    },
    getActiveAlerts: function(alerts, camera) {
      let counter = 0;
      alerts.forEach(function(alert){
        if(alert.alert_status !== 'dismissed'){
          counter++;
          HTTP.put('/camera', {
            "id": camera.id,
            "status": "alert"
          }).catch(function (error) {
            console.log(error);
          });
          //camera.status = 'alert'
        }
        if(alert.alert_type === 'High Temperature'){
          alert.color = 'yellow darken-3'
        }
        else if(alert.alert_type === 'AI Analysis'){
          alert.color = 'orange darken-3'
        }
        else if(alert.alert_type === 'Danger'){
          alert.color = 'red darken-2'
        }
        else{
          alert.color = '#2196F3'
        }
      })
      if(counter === 0){
        camera.status = 'good';
        HTTP.put('/camera', {
          "id": camera.id,
          "status": "good",
        }).catch(function (error) {
          console.log(error);
        });
      }
      return counter;
    },
    getAlertCount (alert) {
      if (this.selected.includes(alert)) {
        // Removing the alert
        this.selected.splice(this.selected.indexOf(alert), 1);
      } else {
        this.selected.push(alert);
      }
    },
    dismissAlert (alertId, cameraId, alertType) {
      //console.log('Alert to dismiss: ', alertId, " on camera ", cameraId, " with alert type of ", alertType)
      HTTP.put('/alert', {
        "id": alertId,
        "cameraId": cameraId,
        "status": "dismissed",
        "alertType": alertType

      }).then(response => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    shapeAlertImagepaths (paths) {
      var images = paths.split(",");
      var index = 0;
      while (index < images.length) {
        images[index] = process.env.VUE_APP_CHART_BASE_URL + "/" + images[index].replace('[','').replace(']','' ).replace(' ','' ).replace("'","" ).slice(0,-1)
        index++;
      }
      return `<template><a href="/">images here!</a></template>`
    },
    getFileName(string) {
      var name = string.split("/")
      return name[name.length -1]
    },
    formatDate(datestring) {
      var datetime = new Date(datestring)
      //var date = datetime.split('T')[0]
      //var time = datetime.split('T')[1].split('.')[0]
      //var year = date.split('-')[0]
      //var month = date.split('-')[1]
      //var day = date.split('-')[2]
      return datetime.toString()
      //return month + '/' + day + '/' + year + " " + time;
      //return datetime//moment(datetime).format("MMM Do YYYY, h:mm:ss")
    }
  },
  components: {
    videoPlayer
  }

}
</script>

<style scoped>
  .player {
    width: 480px;
  }
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  }
</style>
