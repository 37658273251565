

<script>
	import { Bar, mixins } from 'vue-chartjs'
	import {HTTPJSON} from '@/api/http-common'
	export default {
		props: {
			camid: Number
		},
		mixins: [mixins.reactiveData],
	//props: ['camid'],
	extends: Bar,
	data() { 
		return {
			props: {
				camid: Number
			},
			chartData: '',
			labels: [],
			colors: [],
			options: {
				title: {
					display: true,
					fontSize: 24,
					text: 'Infrared Intensity Histogram'
				},
				scales: {
					yAxes: [{
							ticks: {
								beginAtZero: true,
								min: 0,
								max: 100
							}
					}],
					xAxes: [{
							ticks: {
								beginAtZero: true
							}
					}]
				},
				maintianAspectRatio: false
			}
		}
	},
	mounted() {
		//this.fetchData()
		const url = String(process.env.VUE_APP_CHART_BASE_URL)  + "/chart/" + String(this.camid)	
		
		setInterval(() => {
			HTTPJSON.get(url)
			.then(response => {
				const responseData = response.data
				this.chartData = {
					labels: this.labels,
					datasets: [{
					label: 'IR Intensity (percentage)',
					backgroundColor: this.colors,
					data: responseData.data,
					scale: 1
					}]
				}
			})
			.catch(e => {
				console.log(e)
			})
			}, 1500)
			
		this.renderChart(this.chartData, this.options)
		//console.log("chartData1: " + this.chartData, this.options)
	},
	created() {
			//console.log("prop2 is:" + this.camid)
			for (let i = 0; i < 256; i++){
				let percentage = Math.round((i / 255) * 100) +'%'
				this.labels.push(percentage)
			}
			for (let i = 0; i < 175; i++){
				this.colors.push('#33537A')
			}
			for (let i = 175; i < 217; i++){
				this.colors.push('#fcd703')
			}
			for (let i = 217; i < 240; i++){
				this.colors.push('#FE6C29')
			}
			for (let i = 240; i < 252; i++){
				this.colors.push('#db4002')
			}
			for (let i = 252; i < 256; i++){
				this.colors.push('#590000')
			}
	},
		components: {
			'bar-chart': this
		}
	}



</script>